import React from "react"
import VisibilitySensor from 'react-visibility-sensor';

import { behaviourTag } from '../../shared/analytics';

const ScrollAnalytics = ({action, ...props}) =>
  <VisibilitySensor
    {...props}
    onChange={isVisible => isVisible && behaviourTag(action)}
  />;

export default ScrollAnalytics;
